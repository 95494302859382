	/*
  	Flaticon icon font: Flaticon
  	Creation date: 29/06/2016 07:52
  	*/

@font-face {
  font-family:"Flaticon";
  src: url("Flaticon.eot");
  src: url("Flaticon.eot?#iefix") format("embedded-opentype"),
       url("Flaticon.woff") format("woff"),
       url("Flaticon.ttf") format("truetype"),
       url("Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
}

.flaticon-affirmative-check-mark:before { content: "\f100"; }
.flaticon-building:before { content: "\f101"; }
.flaticon-check-mark:before { content: "\f102"; }
.flaticon-check-mark-button:before { content: "\f103"; }
.flaticon-circular-check-button:before { content: "\f104"; }
.flaticon-city:before { content: "\f105"; }
.flaticon-city-1:before { content: "\f106"; }
.flaticon-city-2:before { content: "\f107"; }
.flaticon-compass:before { content: "\f108"; }
.flaticon-cone:before { content: "\f109"; }
.flaticon-construction:before { content: "\f10a"; }
.flaticon-construction-vehicle:before { content: "\f10b"; }
.flaticon-drilling-wall:before { content: "\f10c"; }
.flaticon-home:before { content: "\f10d"; }
.flaticon-open-wrench-tool-silhouette:before { content: "\f10e"; }
.flaticon-play-button:before { content: "\f10f"; }
.flaticon-reply-right-arrow:before { content: "\f110"; }
.flaticon-road:before { content: "\f111"; }
.flaticon-road-perspective-of-curves:before { content: "\f112"; }
.flaticon-square:before { content: "\f113"; }
.flaticon-tick-inside-a-circle:before { content: "\f114"; }
.flaticon-transport:before { content: "\f115"; }
.flaticon-truck:before { content: "\f116"; }
.flaticon-worker-with-shovel:before { content: "\f117"; }